<template>
    <div id="home" class="py-3 home-wrapper">
        <HomeItemBloc v-for="(homeItem, key) in homeItems" :key="`home-item-${key}`" :color="homeItem.color" :class="key % 2 == 0 ? 'ml-6' : 'mr-6'" :homeItem="homeItem"/>
        <!-- <div ref="home-footer" class="py-3 d-flex justify-center align-center home-footer">
            <v-btn fab elevation="0" dark color="primary" width="64" height="64" class="disabled"> <v-img width="32" height="32" src="@/assets/img/icon-home-white.png" contain @click="$router.push({ name: 'Home' })"></v-img> </v-btn>
        </div> -->
    </div>
</template>

<script>
    import HomeItemBloc from '@/components/HomeItemBloc.vue'
    import {pageInfoMixin} from "@/mixins/pageInfoMixin"

    export default {
        components: {
            HomeItemBloc
        },
        mixins: [pageInfoMixin],
        name: 'Home',
        data() {
            return {
                homeItems: [
                    {
                        id: 5,
                        titleKey: "homeItemQBTitle",
                        descriptionKey: "homeItemQBDesc",
                        icon: "icon-users",
                        nextRoute: "ScanProduct",
                        color : "#E3EDF2"
                    },
                    {
                        id: 1,
                        titleKey: "homeItemVerificationTitle",
                        descriptionKey: "homeItemVerificationDesc",
                        icon: "icon-check",
                        nextRoute: "ScanOrder",
                        color : "#E3EDF2"
                    },
                    {
                        id: 2,
                        titleKey: "homeItemOrdersTitle",
                        descriptionKey: "homeItemOrdersDesc",
                        icon: "icon-servers",
                        nextRoute: "Orders",
                        color : "#FAFAFA"
                    },
                    {
                      id: 3,
                      titleKey: "homeItemProductTitle",
                      descriptionKey: "homeItemProductDesc",
                      icon: "icon-product",
                      nextRoute: "ScanProductInfos",
                      color : "#FAFAFA"
                    },
                    {
                      id: 4,
                      titleKey: "homeItemCustomerTitle",
                      descriptionKey: "homeItemCustomerDesc",
                      icon: "icon-customer",
                      nextRoute: "ScanCustomer",
                      color : "#E3EDF2"
                    },
                    {
                        id: 6,
                        titleKey: "homeItemSettingsTitle",
                        descriptionKey: "homeItemSettingsDesc",
                        icon: "icon-settings",
                        nextRoute: "Settings",
                        color : "#FAFAFA"
                    }
                ]
            }
        },
        created() {

        },
        methods: {

        },

    }
</script>

<style scoped>
    .home-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        grid-row-gap: 32px;
        max-width: 100vw;
        overflow-x: hidden;
    }

    .home-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
    }

    .translucid-bg {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: hsl(0deg 0% 100% / 50%);
        backdrop-filter: blur(4px);
    }

    .home-footer .disabled {
        opacity: 0.55;
    }
</style>
