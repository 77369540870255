<template>
    <div class="mt-12 mx-2 d-flex flex-column align-center justify-end rounded-xl pa-3 home-item-bloc-wrapper" :style="{background: color}" @click="handleNextRoute()">
        <div class="pa-6 img-wrapper" :style="{color: boxShadowColor}">
            <v-img :src="require(`@/assets/img/${homeItem.icon}.png`)" height="60" width="60" :alt="$t(homeItem.title)" contain></v-img>
        </div>
        <div class="pt-16 info-wrapper">
            <p class="ma-0 my-3 text-center text-h6 font-weight-bold primary--text home-item-title">{{$t(homeItem.titleKey)}}</p>
            <p class="ma-0 px-1 text-center text-caption home-item-desc">{{$t(homeItem.descriptionKey)}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HomeItemBloc",
        props: {
            homeItem: {
                type: Object,
            },
            color: {
                type: String,
                default: "#FAFAFA"
            }
        },
        methods: {
            handleNextRoute() {
                this.$router.push( { name: this.homeItem.nextRoute } )
            }
        },
        computed:{
            boxShadowColor(){
                if(this.color == "#E3EDF2"){
                    return "#cadde6"
                }
                return "#ECECEC"
            }
        }
    }
</script>

<style scoped>
    .home-item-bloc-wrapper{
        position: relative;
    }
    .home-item-bloc-wrapper .img-wrapper{
        position: absolute;
        border-radius: 50%;
        background: white;
        box-shadow: 4px 17px 12px;
        border: solid 1px #f2fafe;
        top: -25%;
        color: #cadde6;
    }
    .home-item-bloc-wrapper .img-wrapper img{
        width: 60px;
        height: 60px;
    }
    .home-item-desc{
        line-height: 2.5ex;
        height: 7.5ex;
    }
</style>